
import * as ipxRuntime$4dCMorFYJK from '/home/runner/work/storm/storm/node_modules/.pnpm/@nuxt+image@1.7.0_@azure+cosmos@4.0.1-alpha.20240402.1_rollup@4.17.2/node_modules/@nuxt/image/dist/runtime/providers/ipx'
import * as azureBlobRuntime$Ljah3RlU6W from '/home/runner/work/storm/storm/providers/azure-blob-provider.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "webp": {
      "modifiers": {
        "format": "webp",
        "quality": 90
      }
    }
  },
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp",
    "webp"
  ],
  "quality": 90
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$4dCMorFYJK, defaults: {} },
  ['azureBlob']: { provider: azureBlobRuntime$Ljah3RlU6W, defaults: {"baseURL":"https://stormblob.blob.core.windows.net/"} }
}
        